<template>
  <div>
    <div class="pb-3">
      <!-- create button -->
      <b-button v-has-permission="'BUS_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('LIST_ADD_LABEL', {catalog: $t('BUS')})  | capitalize }}</span>
      </b-button>
      <!-- create button -->

      <!-- refresh button -->
      <b-button class="ml-2" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL')  | capitalize }}</span>
      </b-button>
      <!-- refresh button -->

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize }}</span>
      </b-button>
      <!-- clear button -->

      <!-- Excel export -->
      <download-excel class="ml-2 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.module"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') | capitalize }}
      </download-excel>
      <!-- Excel export -->
    </div>
    <div>
      <!-- List page filter -->
      <bus-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter"
                       v-model="filter"></bus-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </div>

    <!-- table -->
    <b-table
        id="bus-table"
        ref="busesTable"
        striped
        hover
        bordered
        small
        responsive
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('BUSES')})  | capitalize }}</strong>
        </div>
      </template>

      <!-- floatNumber -->
      <template #head(floatNumber)="">
        <span>{{ labels.floatNumber | capitalize }}</span>
      </template>

      <template #cell(floatNumber)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.floatNumber }}</span>
        </div>
      </template>
      <!-- floatNumber -->

      <!-- economic -->
      <template #head(economic)="">
        <span>{{ labels.economic | capitalize }}</span>
      </template>

      <template #cell(economic)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.economic }}</span>
        </div>
      </template>
      <!-- economic -->

      <!-- routeNo -->
      <template #head(routeNo)="">
        <span>{{ labels.routeNo | capitalize }}</span>
      </template>

      <template #cell(routeNo)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.routeNo }}</span>
        </div>
      </template>
      <!-- routeNo -->

      <!-- gps -->
      <template #head(gps)="">
        <span>{{ labels.gps | capitalize }}</span>
      </template>

      <template #cell(gps)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.gps }}</span>
        </div>
      </template>
      <!-- gps -->

      <!-- brand -->
      <template #head(brand)="">
        <span>{{ labels.brand | capitalize }}</span>
      </template>

      <template #cell(brand)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.brand }}</span>
        </div>
      </template>
      <!-- brand -->

      <!-- tuneUp -->
      <template #head(tuneUp)="">
        <span>{{ labels.tuneUp | capitalize }}</span>
      </template>

      <template #cell(tuneUp)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.tuneUp | date }}</span>
        </div>
      </template>
      <!-- tuneUp -->

      <!-- nextTuneUp -->
      <template #head(nextTuneUp)="">
        <span>{{ labels.nextTuneUp | capitalize }}</span>
      </template>

      <template #cell(nextTuneUp)="data">
        <div style="text-align: left; vertical-align: center">
          <span v-if="data.item.nextTuneUp">{{ data.item.nextTuneUp | date }}</span>
          <span v-else></span>
        </div>
      </template>
      <!-- nextTuneUp -->

      <!-- series -->
      <template #head(series)="">
        <span>{{ labels.series | capitalize }}</span>
      </template>

      <template #cell(series)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.series }}</span>
        </div>
      </template>
      <!-- series -->

      <!-- model -->
      <template #head(model)="">
        <span>{{ labels.model | capitalize }}</span>
      </template>

      <template #cell(model)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.model }}</span>
        </div>
      </template>
      <!-- model -->

      <!-- revalidation -->
      <template #head(revalidation)="">
        <span>{{ labels.revalidation | capitalize }}</span>
      </template>

      <template #cell(revalidation)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.revalidation }}</span>
        </div>
      </template>
      <!-- revalidation -->

      <!-- plates -->
      <template #head(plates)="">
        <span>{{ labels.plates | capitalize }}</span>
      </template>

      <template #cell(plates)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.plates }}</span>
        </div>
      </template>
      <!-- plates -->

      <!-- poliza -->
      <template #head(poliza)="">
        <span>{{ labels.poliza | capitalize }}</span>
      </template>

      <template #cell(poliza)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.poliza }}</span>
        </div>
      </template>
      <!-- poliza -->

      <!-- engine -->
      <template #head(engine)="">
        <span>{{ labels.engine | capitalize }}</span>
      </template>

      <template #cell(engine)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.engine }}</span>
        </div>
      </template>
      <!-- engine -->

      <!-- year -->
      <template #head(year)="">
        <span>{{ labels.year | capitalize }}</span>
      </template>

      <template #cell(year)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.year }}</span>
        </div>
      </template>
      <!-- year -->

      <!-- type -->
      <template #head(type)="">
        <span>{{ labels.type | capitalize }}</span>
      </template>

      <template #cell(type)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.type }}</span>
        </div>
      </template>
      <!-- type -->

      <!-- company -->
      <template #head(company)="">
        <span>{{ labels.company | capitalize }}</span>
      </template>

      <template #cell(company)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.company }}</span>
        </div>
      </template>
      <!-- company -->

      <template #head(enabled)="">
        <span>{{ labels.enabled  | capitalize }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'BUS_UPDATE_PERMISSION'" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'BUS_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.label)">{{ $t('LIST_DELETE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'BUS_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.label)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'BUS_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.label)" v-else>
              {{ $t('LIST_ENABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <!-- table -->
    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="bus-table">
          </b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {DateFilter, Form, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import Buses from "@/buses";
import eventBus from "@/events";
import BusListFilter from "@/buses/BusListFilter";
import PageSize from "@/components/PageSize";

export default {
  name: "BusList",
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  components: {PageSize, BusListFilter},
  mixins: [Table, Form, ProcessWithLoadingAndMessage, DateFilter],
  mounted() {
    eventBus.$on('LIST_COUNT_BUS_EVENT', (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {},
  data() {
    return {
      labels: {
        floatNumber: this.$t('BUS_FLOAT_NUMBER_LABEL'),
        economic: this.$t('BUS_ECONOMIC_LABEL'),
        routeNo: this.$t('BUS_ROUTE_NO_LABEL'),
        gps: this.$t('BUS_GPS_LABEL'),
        brand: this.$t('BUS_BRAND_LABEL'),
        tuneUp: this.$t('BUS_TUNE_UP_LABEL'),
        nextTuneUp: this.$t('BUS_NEXT_TUNE_UP_LABEL'),
        series: this.$t('BUS_SERIES_LABEL'),
        model: this.$t('BUS_MODEL_LABEL'),
        revalidation: this.$t('BUS_REVALIDATION_LABEL'),
        plates: this.$t('BUS_PLATES_LABEL'),
        poliza: this.$t('BUS_POLIZA_LABEL'),
        engine: this.$t('BUS_ENGINE_LABEL'),
        year: this.$t('BUS_YEAR_LABEL'),
        type: this.$t('BUS_TYPE_LABEL'),
        company: this.$t('BUS_COMPANY_LABEL'),
        enabled: this.$t('LIST_ENABLED_LABEL'),
        module: this.$t('BUSES'),
        excel: this.$t('BUSES') + '.xls',
      },
      registerPage: 'BUS_REGISTER_TITLE',
      editPage: 'BUS_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    controller() {
      return Buses;
    }, fields() {
      return [
        {key: 'floatNumber', sortable: true, tdClass: 'customTd'},
        {key: 'economic', sortable: true, tdClass: 'customTd'},
        {key: 'routeNo', sortable: true, tdClass: 'customTd'},
        {key: 'gps', sortable: true, tdClass: 'customTd'},
        {key: 'brand', sortable: true, tdClass: 'customTd'},
        {key: 'tuneUp', sortable: true, tdClass: 'customTd'},
        {key: 'nextTuneUp', sortable: true, tdClass: 'customTd'},
        {key: 'series', sortable: true, tdClass: 'customTd'},
        {key: 'model', sortable: true, tdClass: 'customTd'},
        {key: 'revalidation', sortable: true, tdClass: 'customTd'},
        {key: 'plates', sortable: true, tdClass: 'customTd'},
        {key: 'poliza', sortable: true, tdClass: 'customTd'},
        {key: 'engine', sortable: true, tdClass: 'customTd'},
        {key: 'year', sortable: true, tdClass: 'customTd'},
        {key: 'type', sortable: true, tdClass: 'customTd'},
        {key: 'company', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'enabledTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    }, tableRef() {
      return this.$refs.busesTable;
    }, excelFields() {
      const fields = {};
      fields[this.labels.floatNumber] = 'floatNumber';
      fields[this.labels.economic] = 'economic';
      fields[this.labels.routeNo] = 'routeNo';
      fields[this.labels.gps] = 'gps';
      fields[this.labels.brand] = 'brand';
      fields[this.labels.tuneUp] = 'tuneUp';
      fields[this.labels.nextTuneUp] = 'nextTuneUp';
      fields[this.labels.series] = 'series';
      fields[this.labels.model] = 'model';
      fields[this.labels.revalidation] = 'revalidation';
      fields[this.labels.plates] = 'plates';
      fields[this.labels.poliza] = 'poliza';
      fields[this.labels.engine] = 'engine';
      fields[this.labels.year] = 'year';
      fields[this.labels.type] = 'type';
      fields[this.labels.company] = 'companys'
      return fields;
    }
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>
